import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';

const InputWithLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
  }
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 11px;
  margin: 2px;
  text-align: left;
  font-family: 'Aeonik-Regular';
`;

export const MultiSelectFilter = ({ label, state, setState, options, disabled, error, removeError }) => {
  const handleChange = (value) => setState(value);

  return (
    <InputWithLabelWrapper>
      <Label className='required'>{label}</Label>
      <Select
        mode="multiple" // Enable multiple selection
        showSearch
        value={state}
        defaultValue={undefined} // Set defaultValue to undefined to have it empty by default
        onClick={() => removeError(label)}
        disabled={disabled}
        onChange={handleChange}
        maxTagCount="responsive"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        }
      >
        {options &&
          options.map((value) => {
            return (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            );
          })}
      </Select>
      {error && error.map((field) => {
        if (field.error === label) {
          return <p className='error_msg_uploadgfile'>{field.message}</p>;
        }
      })}
    </InputWithLabelWrapper>
  );
};

export default MultiSelectFilter;