import styled from 'styled-components';
import { GrDocumentDownload } from 'react-icons/gr';
import API from 'Pages/TravelCompliancePage/api';
import {DownloadOutlined } from '@ant-design/icons';

export const Download = ({ record }) => {
  const { uuid, fileName, extension } = record;
  const file = fileName + '.' + extension;

  const handleClick = () => {
    API.downloadFile(uuid, file);
  };

  return (
    <div>
      <DownloadOutlined onClick={() => handleClick()} />
    </div>
  );
};

export const DownloadImage = styled(GrDocumentDownload)`
  cursor: pointer;
  font-size: 20px;
  padding: 0;
  margin: 0;
`;

export const Content = styled.div`
  max-width: 75%;
  margin: 5vh auto 6vh auto;
  padding-bottom: 10vh;
`;

export const TableWrapper = styled.div`
  td.center-values {
    text-align: center;
  }
`;
