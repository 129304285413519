import { useState, useEffect } from 'react';
import { Table as AntTable } from 'antd';

import * as Styles from './Table.styles';
import API from 'Pages/TravelCompliancePage/api';
import Filters, { useFiltersState } from '../Filters';
import { mapDataToTable, tableDefinition, itIsUID } from './table.definition';

const Table = ({ params }) => {

  const filtersState = useFiltersState();
  const [tableData, setTableData] = useState();
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [queryPageState, setQueryPageState] = useState(1);
  const [queryRowsCountState, setRowsCountState] = useState(1);
  const [queryFiltersState, setQueryFiltersState] = useState(filtersState);
  const [querySorterState, setQuerySorterState] = useState({
    sort_field: undefined,
    sort_direction: undefined,
  });

  const applyFiltersState = () => {
    setQueryPageState(1);
    setQueryFiltersState(filtersState);
  };

  const handleChange = (pagination, _, sorter) => {
    setIsDataLoading(true)
    setQueryPageState(pagination.current);
    setQuerySorterState({ sort_field: sorter.field, sort_direction: sorter.order });
  };

  useEffect(() => {
    //setIsDataLoading(true); //if data fetched too long, please uncomment this line - this is loading for table
    itIsUID(params) ?
      API.getUserFile(params).then(
        (response) => {
          setTableData(mapDataToTable(response))
          setIsDataLoading(false);
        }
      )
      : API.getUserFiles(params, queryPageState, queryFiltersState, querySorterState).then(
        (response) => {
          setTableData(mapDataToTable(response));
          setQueryPageState(response.page);

          queryRowsCountState !== response.rowsCount && setRowsCountState(response.rowsCount);
          setIsDataLoading(false);
        }
      );
  }, [queryFiltersState, queryPageState, querySorterState, params]);

  return (
    <Styles.Content>
      <Styles.TableWrapper>
        <Filters
          filtersState={filtersState}
          applyFilters={applyFiltersState}
          isFiltersDisable={false}
        />
        <AntTable
          onChange={handleChange}
          dataSource={tableData}
          loading={isDataLoading}
          columns={tableDefinition}
          pagination={{
            current: queryPageState,
            total: queryRowsCountState,
            pageSize: 10,
            showSizeChanger: false,
            position: ['bottomCenter'],
          }}
        />
      </Styles.TableWrapper>
    </Styles.Content>
  );
};

export default Table;
